import { Language } from 'interfaces/Language'
import FooterFrameDe from 'assets/homepage/footer-frame-de.webp'
import FooterFrameFr from 'assets/homepage/footer-frame-fr.webp'
import FooterFramePl from 'assets/homepage/footer-frame-pl.webp'
import FooterFrameIt from 'assets/homepage/footer-frame-it.webp'
import FooterFrameUk from 'assets/homepage/footer-frame-uk.webp'
import FooterFrameTr from 'assets/homepage/footer-frame-tr.webp'

export const getFooterPicture = (locale: Language) => {
    switch (locale) {
        // TODO: add new footer frame
        case Language.German:
            return FooterFrameDe
        case Language.French:
            return FooterFrameFr
        case Language.Polish:
            return FooterFramePl
        case Language.Italian:
            return FooterFrameIt
        case Language.Spanish:
        case Language.ArgentineSpanish:
        case Language.ChileanSpanish:
        case Language.ColombianSpanish:
        case Language.PeruvianSpanish:
        case Language.AmericanEnglish:
        case Language.BritishEnglish:
        case Language.MexicanSpanish:
        case Language.Greek:
        case Language.Romanian:
        case Language.BrazilianPortuguese:
            return FooterFrameUk
        case Language.Turkish:
            return FooterFrameTr
        default:
            throw new Error(`Unknown language: ${locale}`)
    }
}
