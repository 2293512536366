import { CountryCode } from 'interfaces/Country'

export const getDomainSwitchingLabelBasedOnCountryCode = (countryCode: CountryCode) => {
    switch (countryCode) {
        case CountryCode.Switzerland:
        case CountryCode.Austria:
        case CountryCode.Germany:
            return 'Aus welchem Land kommst du?'
        case CountryCode.Poland:
            return 'Z jakiego kraju pochodzisz?'
        case CountryCode.France:
            return 'De quel pays es-tu originaire ?'
        case CountryCode.UnitedStates:
            return 'What country are you from?'
        case CountryCode.UnitedKingdom:
            return 'What country are you from?'
        case CountryCode.Italy:
            return 'Da quale paese provieni?'
        case CountryCode.Spain:
        case CountryCode.Colombia:
        case CountryCode.Mexico:
        case CountryCode.Chile:
        case CountryCode.Argentina:
        case CountryCode.Peru:
            return '¿De que país eres?'
        case CountryCode.Turkey:
            return 'Hangi ülkeden giriş yapıyorsun?'
        case CountryCode.Romania:
            return 'Din ce țară vii?'
        case CountryCode.Greece:
            return 'Από ποια χώρα είστε;'
        case CountryCode.Brazil:
            return 'De qual país você é?'
    }
}
