import React from 'react'
import { Body1, ButtonText, Overline } from 'stories/elements/Typography/Text'
import theme from 'stories/utils/theme'
import styled, { css } from 'styled-components'
import GooglePlayIcon from 'assets/icons/google-play.svg'
import AppleAppStoreIcon from 'assets/icons/apple-app-store.svg'
import GooglePlayDarkIcon from 'assets/icons/google-play-dark.svg'
import GooglePlayColorIcon from 'assets/icons/google-play-color.svg'
import AppleAppStoreDarkIcon from 'assets/icons/apple-app-store-dark.svg'
import { useTranslation } from 'next-i18next'
import { redirectToAppDownloadUrlAsync } from 'lib/features/app-links'
import HighestRatingIcon from 'assets/icons/star.svg'
import { KnowunityNumbers } from 'lib/constants/KnowunityNumbers'

const Container = styled.div<{
    isDark?: boolean
    backgroundColor?: string
    isNotLaunched?: boolean
    showRating?: boolean
    showAsNative?: boolean
}>`
    width: unset;
    background: ${({ isDark }) => (isDark ? theme.colors.headlineDark : theme.colors.white)};
    display: flex;
    justify-content: center;
    border-radius: ${theme.borderRadius.normal};
    padding: 5px 15px;
    place-items: center;
    grid-gap: 15px;
    cursor: ${({ isNotLaunched }) => (isNotLaunched ? 'normal' : 'pointer')};
    min-height: 45px;
    min-width: 165px;
    position: relative;
    ${({ showRating }) =>
        showRating
            ? css`
                  padding-right: 50px;
              `
            : ''}
    & svg {
        height: 25px;
    }
    .text {
        min-width: 90px;
        width: auto;
    }
    .rating {
        display: flex;
        gap: 5px;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 10px;
        svg {
            fill: ${theme.colors.sunlightYellow};
        }
    }
    ${({ backgroundColor }) =>
        backgroundColor
            ? css`
                  background-color: ${backgroundColor};
              `
            : ''};

    ${({ showAsNative }) =>
        showAsNative
            ? css`
                  border: 0.79px solid #a6a6a6;
                  width: fit-content;
                  svg {
                      position: relative;
                  }
              `
            : ''};
`

interface Props {
    type: 'android' | 'ios'
    referrerScreen: string
    onClick?: () => void
    isDark?: boolean
    backgroundColor?: string
    downloadDeepLinkPath?: string
    isNotLaunched?: boolean
    addRating?: boolean
    showAsNative?: boolean
}

const DownloadButton = ({
    type,
    onClick,
    isDark,
    backgroundColor,
    downloadDeepLinkPath,
    referrerScreen,
    isNotLaunched,
    addRating,
    showAsNative,
}: Props) => {
    const { t } = useTranslation('common')
    const isAndroid = type === 'android'
    const store = isAndroid ? 'Google Play' : 'App Store'

    const rating = isAndroid ? KnowunityNumbers.googleReviewRating : KnowunityNumbers.appleReviewRating

    const onButtonClick = () => {
        if (isNotLaunched) return
        onClick && onClick()
        redirectToAppDownloadUrlAsync(type, referrerScreen, downloadDeepLinkPath)
    }

    return (
        <Container
            onClick={onButtonClick}
            isDark={isDark}
            className="button"
            backgroundColor={showAsNative ? theme.colors.black : backgroundColor}
            isNotLaunched={isNotLaunched}
            showRating={addRating}
            showAsNative={showAsNative}
        >
            {isAndroid ? (
                showAsNative ? (
                    <GooglePlayColorIcon />
                ) : isDark ? (
                    <GooglePlayDarkIcon />
                ) : (
                    <GooglePlayIcon />
                )
            ) : isDark ? (
                <AppleAppStoreDarkIcon />
            ) : (
                <AppleAppStoreIcon />
            )}
            <div className="text">
                <Overline
                    fontFamily="var(--font-open-sans)"
                    fontWeight={400}
                    fontSize={showAsNative ? 12 : 11}
                    color={isDark ? theme.colors.white : theme.colors.subtitleBlack}
                >
                    {showAsNative
                        ? isAndroid
                            ? t('common/downloadInNativeLabelGoogle').toUpperCase()
                            : t('common/downloadInNativeLabelApple')
                        : t('common/downloadIn')}
                </Overline>
                <ButtonText
                    fontFamily={showAsNative ? 'var(--font-inter)' : 'var(--font-open-sans)'}
                    fontWeight={showAsNative ? 400 : 600}
                    color={isDark ? theme.colors.white : theme.colors.subtitleBlack}
                >
                    {store}
                </ButtonText>
            </div>
            {addRating ? (
                <div className="rating">
                    <Body1 fontFamily="var(--font-inter)" color={theme.colors.white}>
                        {rating}
                    </Body1>
                    <HighestRatingIcon />
                </div>
            ) : null}
        </Container>
    )
}

export default DownloadButton
